import axios from 'axios'
import axiosRetry from 'axios-retry';
import {store as reduxStore} from "../../store";
/*
 global window
 */

const client = (() => {
    return axios.create({
        baseURL: process.env.REACT_APP_ID_ENDPOINT
    });
})();

axiosRetry(client, { retries: 3 });

const request = function(options, store) {
    let user = reduxStore.getState()?.palidin?.user;

    const onSuccess = function(response) {
        console.debug('Request Successful!', response);
        return response.data;
    };

    const onError = function(error) {
        return Promise.reject(error.response || error.message);
    };

    let AUTH_TOKEN = user ? btoa(`${user?.acuantApiSubscriptionId || process.env.REACT_APP_SUBSCRIPTION_ID};${user.acuantEmail}:${user.acuantPassword}`) : btoa( process.env.REACT_APP_USER_NAME + ':' + process.env.REACT_APP_PASSWORD);
    options.headers = {
        "Authorization": `Basic ${AUTH_TOKEN}`,
    };
    //


    return client(options)
        .then(onSuccess)
        .catch(onError);
};


export default request;
