import React, {Component} from 'react';

export default class Header extends Component {
    render() {
        return (
            <header>
                <div className='column wrapper'>
                    <div className='logo'/>
                    <span className='moto'>Web ID Document Authentication</span>
                </div>
            </header>
        );
    }
}

