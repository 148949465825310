import {configReducer} from "./screens/reducers/configReducer";
import {processedDataReducer} from "./screens/reducers/processedDataReducer";
import {idPropertiesReducer} from "./screens/reducers/idPropertiesReducer";
import {palidinReducer} from "./screens/reducers/palidinReducer";

export default {
    config: configReducer,
    palidin: palidinReducer,
    processedData: processedDataReducer,
    idProperties: idPropertiesReducer
}
