import React, { Component, Fragment } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Header from "./Header";
import { decrementSidesLeft, setCardOrientation, setCardType } from "./actions/idPropertiesActions";
import { setInstanceID, submitBackID, submitFrontID } from "./actions/configActions";
import { newData } from "./actions/processDataActions";
import Processing from "./Processing";

class CapturePhoto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputValue: null,
            processing: false
        }
    }

    isIEorEDGE() {
        return navigator.appName === 'Microsoft Internet Explorer' || (navigator.appName === "Netscape" && navigator.appVersion.indexOf('Edge') > -1);
    }

    componentDidMount() {
        if (!this.props.instanceID) {
            this.props.setInstanceID();
            this.props.newData();
        }
        if (this.props.location && this.props.location.state) {
            if (this.props.location.state.isRetry) {
                this.navigateCamera();
            }
        }
    }

    getOrientationCopy() {
        return this.props.orientation === 0 ? 'front' : 'back';
    }

    getCardTypeCopy() {
        switch (this.props.cardType) {
            case 1:
                return 'ID card';
            case 2:
                return 'medical card';
            default:
                return 'ID card';
        }
    }

    openCamera(type){
        this.props.setCardType(type);
        this.navigateCamera();
    }

    navigateCamera(){
        this.props.history.push('/capture/camera');
    }

    render() {
        if (this.state.processing) {
            return <Processing />
        }
        return (
            <Fragment>

                <Header />

                <div className='body column capture_photo'>

                    <div className='row wrapper description_container'>
                        <p className='description'>Upload a clear picture of the {this.getOrientationCopy()} of your {this.getCardTypeCopy()}.</p>
                    </div>

                    <div className='instructions'>
                        <p>To achieve best results, please follow these guidelines:​</p>
                        <ul>
                            <li>Clean your mobile device’s camera(s)​</li>
                            <li>Clean your document by removing any excess dust/dirt/grime​</li>
                            <li>Use natural light whenever possible (avoid fluorescent lighting environments or camera flash as these cause glare when capturing the document image)​</li>
                            <li>Center the document in the frame and ensure it covers 85-90% of the available space in your phone screen</li>
                            <li>Tap the screen and allow camera to focus</li>
                            <li>Keep a steady hand. Image and text should be clear before
                                you take the picture</li>
                            <li>Tap the white button to capture the document image</li>
                        </ul>
                    </div>

                    <div className="capture_group">

                        <div className='row wrapper capture_container'>


                            {this.props.sidesLeft === 2 &&
                            <img alt='idscango' className={'capture'} src={require('../assets/images/illustration1@3x.png')} />

                            }
                            {this.props.sidesLeft === 1 &&
                                <img alt='idscango' className={'capture'} src={this.props.frontSubmitted ? require('../assets/images/illustration1@3x.png') : require('../assets/images/IDback@3x.png')} />
                            }
                        </div>

                        <div className="wrapper column capture_controls">

                            {this.props.sidesLeft === 2 &&
                            <Fragment>
                                {process.env.REACT_APP_IDPASSPORT_ENABLED === 'true' &&
                                <label className='btn' onClick={() => this.openCamera(1)}>
                                    <p className={'buttonBgText'}>Capture front of ID card</p>
                                </label>
                                }
                                {process.env.REACT_APP_MEDICAL_CARD_ENABLED === 'true' &&
                                <label className='btn' onClick={() => this.openCamera(2)}>
                                    <p className={'buttonBgText'}>Capture medical card</p>
                                </label>
                                }
                            </Fragment>
                            }

                            {this.props.sidesLeft === 1 &&
                            <label className={'btn'} onClick={() => this.openCamera(this.props.cardType)} >
                                <p className='buttonBgText'>Capture {this.getOrientationCopy()} of {this.getCardTypeCopy()}</p>
                            </label>
                            }
                            {this.props.sidesLeft === 1 && this.props.cardType === 2 &&
                            <div className={'btn outline'} onClick={() => { this.props.history.push('/results/medicard') }}>
                                <p className={'buttonBdText'}>Skip this step</p>
                            </div>
                            }

                        </div>

                    </div>

                </div>

            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        orientation: state.idProperties.orientation,
        cardType: state.idProperties.cardType,
        sidesLeft: state.idProperties.sidesLeft,
        frontSubmitted: state.config.frontSubmitted,
        backSubmitted: state.config.backSubmitted
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setCardType, setInstanceID, setCardOrientation, decrementSidesLeft, submitFrontID, submitBackID, newData }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CapturePhoto);
