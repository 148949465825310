import storage from "redux-persist/es/storage";
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
import {persistCombineReducers, persistStore} from "redux-persist";
import { connectRouter, routerMiddleware } from 'connected-react-router'
import {createStore, applyMiddleware} from 'redux';
import rootReducer from './rootReducer'
import ReactGA from 'react-ga';
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

const loggerMiddleWare = store => next => action => {
    next(action);
};
const gaTrackingMiddleware = store => next => action => {
    if (action.type === '@@router/LOCATION_CHANGE') {
        const nextPage = `${action.payload.location.pathname}`;
        trackPage(nextPage,store.getState().kiosk);
    }
    return next(action);
};
const trackPage = (page,kiosk) => {
    ReactGA.ga('send', 'pageview', page);
};

/**
 * cardType: 1 for ID/Passport, 2 for Medicard
 */
const initialState = {
    config: {
        instanceID: null,
        frontSubmitted: false,
        backSubmitted: false
    },
    palidin: {
        user: null
    },
    processedData: {
        faceMatch: null,
        result: null
    },
    idProperties: {
        cardType: 0,
        orientation: 0,
        sidesLeft: 2
    }
};

export const history = createHistory({basename: process.env.REACT_APP_BASENAME});

const config = {
    key: 'idscango',
    storage,
    blacklist: ['config', 'processedData', 'idProperties', 'router']
};

const reducer = persistCombineReducers(config, rootReducer);

function configureStore() {
    let store = createStore(
        connectRouter(history)(reducer),
        initialState,
        applyMiddleware(thunk, loggerMiddleWare, routerMiddleware(history), gaTrackingMiddleware)
    );
    let persistor = persistStore(store);
    return {persistor, store};
}

export const {persistor, store} = configureStore();
