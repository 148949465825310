import React, {Component, Fragment} from 'react';

export default class MobileOnly extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <Fragment>

                <div className='body column'>

                    <div className='column wrapper description_container desktop_error'>
                        <p className={'description'}>PALIDIN WebID is not available for desktop computers.</p>
                        <p className={'description'}>Please open the authentication invitation on a mobile device.</p>
                    </div>

                </div>

            </Fragment>
        )
    }

}
