import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import Header from "./Header";

class InvitationFail extends Component {
    render() {
        return (
            <Fragment>

                <div className='body column capture_photo'>
                    <div className='column wrapper big_title fail'>
                        Error
                    </div>
                    <div className='row wrapper description_container'>
                        <p className='description' style={{width: '100%',textAlign:'center'}}>Something went wrong. The invitation url has already been used or is invalid.</p>
                    </div>
                </div>

            </Fragment>
        );
    }
}



export default connect(null,null)(InvitationFail);
