import React, { Component, Fragment } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Header from "./Header";
import { decrementSidesLeft, setCardOrientation, setCardType } from "./actions/idPropertiesActions";
import { setInstanceID, submitBackID, submitFrontID } from "./actions/configActions";
import Processing from "./Processing";

class Info extends Component {


    render() {
        return (
            <Fragment>
                <Header />

                <div className='body column capture_photo'>

                    <div className='row  wrapper description_container'>
                        <p className='description'>Instructions:</p>
                    </div>

                    <div className='column wrapper info_container'>
                        <div className='column info_container'>
                            <div className='row step'>
                                <span className='step-label'>Step 1</span>
                                <span className='step-text'>Capture the front of the ID</span>
                            </div>
                            <div className='row step'>
                                <span className='step-label'>Step 2</span>
                                <span className='step-text'>Confirm the quality of the image</span>
                            </div>
                            <div className='row step'>
                                <span className='step-label'>Step 3</span>
                                <span className='step-text'>Capture back of the ID</span>
                            </div>
                            <div className='row step'>
                                <span className='step-label'>Step 4</span>
                                <span className='step-text'>Confirm the quality of the image</span>
                            </div>
                            <div className='row step'>
                                <span className='step-label'>Step 5</span>
                                <span className='step-text'>Capture selfie picture to confirm the ID is yours</span>
                            </div>
                            <div className='row step'>
                                <span className='step-label'>Step 6</span>
                                <span className='step-text'>Confirm the quality of the selfie</span>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="wrapper column capture_controls">
                    <label className={'btn'} onClick={() => this.props.history.push({pathname: '/capture/photo'})} >
                        <p className='buttonBgText'>Scan Document</p>
                    </label>
                </div>
            </Fragment>
        );
    }
}



export default connect(null,null)(Info);
