import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import Header from "./Header";
import PalidinService from "../services/api/palidin";
import {bindActionCreators} from "redux";
import {decrementSidesLeft, setCardOrientation, setCardType} from "./actions/idPropertiesActions";
import {setUser, removeUser} from "./actions/palidinActions";

class Loading extends Component {


    componentDidMount() {
        let token = this.props.location.search.split('=')[1];
        PalidinService.scanToken(token)
            .then(res => {
                this.props.setUser({
                    ...res,
                    acuantEmail: res.acuantEmail || res.AcuantEmail,
                    acuantPassword: res.acuantPassword || res.AcuantPassword,
                    accessToken: res.accessToken || res.AccessToken,
                    invitationId: res.invitationId || res.InvitationId,
                    invitationToken: res.invitationToken || res.InvitationToken,
                    acuantApiSubscriptionId: res.acuantApiSubscriptionId || res.AcuantApiSubscriptionId,
                });
                this.props.loadScript();
            })
            .catch(err => {
                this.props.history.push('/error/invalid-invitation');
            })
    }

    render() {
        return (
            <Fragment>
                <Header />

                <div className='body column capture_photo'>

                    <div className='row wrapper description_container'>
                        <p className='description'>Loading</p>
                    </div>

                </div>
            </Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setUser, removeUser}, dispatch);
}

export default connect(null,mapDispatchToProps)(Loading);
