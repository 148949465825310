import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import Processing from "./Processing";
import {bindActionCreators} from "redux";
import {setCaptured} from "./actions/capturedActions";
/*
global Raven
 */

class AcuantReactCamera extends Component {
  constructor(props) {
    super(props);
    this.detectedCount = 0;
    this.state = {
      processing: false,
      liveCaptureFailed: false
    }
  }

  setProcessing(value) {
    this.setState({
      ...this.state,
      processing: value
    })
  }

  setLiveCaptureFailed(value) {
    this.setState({
      ...this.state,
      liveCaptureFailed: value
    })
  }

  onCaptured(_) {
    //document captured
    //this is not the final result of processed image
    //show a loading screen until onCropped is called
    this.setProcessing(true);
  }

  onCropped(response) {
    this.setProcessing(false);
    if (response) {
      //use response
      // this.props.setCaptured(response);
      // Image check sharpness and glare scores should be >/= to 75 and the DPI >/= 500​
      try {
        Raven.captureBreadcrumb({
          message: "Metrics report",
          category: "action",
          data: {
            sharpness: response.sharpness,
            glare: response.glare,
            dpi: response.dpi
          },
        });
      } catch (e) {
        console.log(e);
      }
      this.props.history.push('/photo/confirm', {
        blurry: response.sharpness < 75,
        blur: response.sharpness,
        hasGlare: response.glare < 75,
        glare: response.glare,
        lowQuality: response.dpi < 500,
        dpi: response.dpi,
        cardImage: response.image.data,
      });
    }
    else {
      //cropping error
      //restart capture
      this.startCamera()
    }
  }

  onFrameAvailable(_) {
    //do nothing
  }

  startCamera(){
    if (window.AcuantCameraUI) {
      //bypass wasm thing
      this.startManualCapture();
      return;
      if (window.AcuantCamera.isCameraSupported) {
        window.AcuantCameraUI.start({
          onCaptured: this.onCaptured.bind(this),
          onCropped: this.onCropped.bind(this),
          onFrameAvailable: this.onFrameAvailable.bind(this)
        }, this.onError.bind(this));
      }
      else {
        this.startManualCapture();
      }
    }
  }

  startManualCapture() {
    window.AcuantCamera.startManualCapture({
      onCaptured: this.onCaptured.bind(this),
      onCropped: this.onCropped.bind(this)
    }, this.onError.bind(this));
  }

  onError(_, code) {
    if (code === "repeat-fail") {
      this.setLiveCaptureFailed(true);
    } else if (code === "sequence-break") {
      alert("Live Capture failed. Please try again.")
      this.props.history.replace("/capture/photo")
    } else {
      alert("This device does not support Live Capture. Manual Capture will be started. Please try again.")
      this.props.history.replace("/capture/photo")
    }
  }

  componentDidMount() {
    this.startCamera()
  }

  componentWillUnmount() {
  }

    render() {
      if (this.state.processing) {
        return <div id="acuant-processing" className={'acuant-processing'}><Processing/></div>
      } else if (this.state.liveCaptureFailed) {
        return (
            <Fragment>
              {/*<Header />*/}
              <div className='body column'>
                <div className='row wrapper icon' />
                <div className='row wrapper description_container'>
                  <p className='description'>Live camera failed. </p>
                </div>
                <div className="wrapper column">
                  <label className='btn' onClick={() => this.startManualCapture()}>
                    <p className={'buttonBgText'}>Start manual capture</p>
                  </label>
                </div>
              </div>
            </Fragment>
        )
      } else {
        return (
            <div id="acuant-camera"></div>
        )
      }
    }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setCaptured }, dispatch);
}

export default connect(null, mapDispatchToProps)(AcuantReactCamera);
