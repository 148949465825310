import axios from 'axios'
import axiosRetry from 'axios-retry';
/*
 global window
 */

const client = (() => {
    return axios.create({
        baseURL: process.env.REACT_APP_PALIDIN_ENDPOINT
    });
})();

axiosRetry(client, { retries: 3 });

const request = function(options, store) {
    const onSuccess = function(response) {
        console.debug('Request Successful!', response);
        return response.data;
    };

    const onError = function(error) {
        return Promise.reject(error.response || error.message);
    };

    // let AUTH_TOKEN = btoa(`${process.env.REACT_APP_USER_NAME}:${process.env.REACT_APP_PASSWORD}`);
    options.headers = {
        "api-version": "v1",
        "Ocp-Apim-Subscription-Key": process.env.REACT_APP_PALIDIN_SUBSCRIPTION,
        "Ocp-Apim-Trace": true,
        "Access-Control-Request-Headers": "api-version,content-type,ocp-apim-subscription-key,ocp-apim-trace",
        "X-Access-Control-Request-Method": "POST",
        "X-Access-Control-Request-Headers": "authorization,cache-control,ocp-apim-header-api-version,ocp-apim-header-ocp-apim-subscription-key,ocp-apim-header-ocp-apim-trace,ocp-apim-header-origin,ocp-apim-method,ocp-apim-resource-group,ocp-apim-service-name,ocp-apim-subscription,ocp-apim-url"
    };


    return client(options)
        .then(onSuccess)
        .catch(onError);
};


export default request;
