import requestPalidin from '../shared/requestPalidin';

function scanToken(token) {

    return requestPalidin({
        url: `/scan/token?token=${token}`,
        method: 'POST'
    });

}

function scanProcess(accessToken, invitationId, acuantTransactionId, selfie) {
    return requestPalidin({
        url: `/scan/process?accessToken=${accessToken}&invitationId=${invitationId}&acuantTransactionId=${acuantTransactionId}`,
        method: 'POST',
        data: selfie
    });
}

const PalidinService = {
    scanToken,
    scanProcess
};

export default PalidinService;
