import React, {Component} from 'react';
import '@babel/polyfill';
import {Switch, Route, Redirect} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router'
import {PersistGate} from 'redux-persist/es/integration/react';
import {isMobile, isTablet} from "react-device-detect";
import {Provider} from 'react-redux';
import CapturePhoto from './screens/CapturePhoto';
import CaptureSelfie from './screens/CaptureSelfie';
import Results from './screens/Results/index';
import Error from './screens/Error/index';
import "./styles/main.css";
import ProcessedImageResult from "./screens/ProcessedImageResult";
import AcuantReactCamera from "./screens/AcuantReactCamera";
import Info from "./screens/Info";
import Success from "./screens/Success";
import Loading from "./screens/Loading";
import InvitationFail from "./screens/InvitationFail";
/*
global Raven
 */

class App extends Component {

    constructor(props){
        super(props);
        this.state = {
            isAcuantSdkLoaded: false
        }
        this.isInitialized = false;
        this.isIntializing = false;
    }

    checkIpad() {
        const ua = window.navigator.userAgent;
        if (ua.indexOf('iPad') > -1) {
            return true;
        }

        if (ua.indexOf('Macintosh') > -1) {
            try {
                document.createEvent("TouchEvent");
                return true;
            } catch (e) {
                return false;
            }
        }

        return false;
    }

    isOldiOS() {
        let ua = navigator.userAgent;
        let keyPhrase = "iPhone OS";
        const keyPhrase2 = "iPad; CPU OS";
        let index = ua.indexOf(keyPhrase);
        if (index < 0) {
            keyPhrase = keyPhrase2;
            index = ua.indexOf(keyPhrase);
        }
        if (index >= 0) {
            let version = ua.substring(index + keyPhrase.length + 1, index + keyPhrase.length + 3);
            try {
                let versionNum = parseInt(version);
                if (versionNum && versionNum < 13) {
                    return true;
                } else {
                    return false;
                }
            } catch (_) {
                return false;
            }
        } else {
            return false;
        }
    }


    componentDidMount() {
        let mobileOrTablet = isMobile || this.checkIpad();
        if (process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID && process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID.length > 0) {
            Raven.config(process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID).install()
        }

        if (process.env.REACT_APP_MOBILE_ONLY === 'true') {
            // console.log(deviceDetect());
            if (isTablet || this.checkIpad()) {
                document.getElementById('root').classList.add('tablet-view');
            }
            if (!mobileOrTablet) {
                this.props.routerHistory.replace('/error/mobileonly');
                document.body.classList.add('mobile-only');
                this.setState({isAcuantSdkLoaded: true});
            } else {
                if (!this.props.config) {
                    if (this.props.routerHistory.location.search) {

                        this.props.routerHistory.replace('/user' + this.props.routerHistory.location.search);
                        // this.props.routerHistory.replace('/info');
                    } else {
                        this.setState({isAcuantSdkLoaded: true});
                        // this.props.routerHistory.replace('/info');
                        this.props.routerHistory.replace('/error/invalid-invitation')
                    }
                    // this.props.routerHistory.replace('/');
                }
                // this.loadScript();
            }
        } else {
            if (!this.props.config) {
                if (this.props.routerHistory.location.search) {
                    this.props.routerHistory.replace('/user' + this.props.routerHistory.location.search);
                    // this.props.routerHistory.replace('/capture/photo');
                } else {
                    this.setState({isAcuantSdkLoaded: true});
                    this.props.routerHistory.replace('/error/invalid-invitation')
                }
            }
            // this.loadScript();
        }


    }

    loadScript() {
        const sdk = document.createElement("script");
        sdk.src = "AcuantJavascriptWebSdk.min.js";
        sdk.onload = () => window.loadAcuantSdk();
        window.onAcuantSdkLoaded = () => this.initialize();
        document.body.appendChild(sdk);

        const camera = document.createElement("script");
        camera.src = "AcuantCamera.min.js";
        document.body.appendChild(camera);

        const passiveLiveness = document.createElement("script");
        passiveLiveness.src = "AcuantPassiveLiveness.min.js";
        document.body.appendChild(passiveLiveness);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        window.onpopstate = e => {
            let state = this.props.store.getState()
            if( state && state.processedData && state.processedData.sent ) {
                this.props.routerHistory.replace('/error/invalid-invitation')
            } else {
                this.props.routerHistory.replace('/info');
            }
        }
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
        if (process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID && process.env.REACT_APP_SENTRY_SUBSCRIPTION_ID.length > 0) {
            Raven.captureException(error, {extra: errorInfo});
        }
        this.props.routerHistory.push('/error/default')
    }

    initialize(){
        let user = this.props.store.getState()?.palidin?.user;
        if(!this.isInitialized && !this.isIntializing){
            this.isIntializing = true;
            window.AcuantJavascriptWebSdk.initialize(
                (function(user){
                    return user ? btoa(`${user.acuantEmail}:${user.acuantPassword}`) : btoa( process.env.REACT_APP_USER_NAME + ':' + process.env.REACT_APP_PASSWORD);
                })(user),
                process.env.REACT_APP_ACUANT_ACAS_ENDPOINT,
                {
                    onSuccess: function() {
                        // acuant suggests we don't run metrics on old ios devices. needs planning as all metrics return -1.
                        window.AcuantJavascriptWebSdk.startWorkers(this.initDone.bind(this));
                    }.bind(this),
                    onFail: function(e,a,b,c){
                        this.isIntializing = false;
                        this.props.routerHistory.push('/error/invalid-invitation');
                        this.setState({
                            isAcuantSdkLoaded:true
                        })
                    }.bind(this)
                });
        }
    }

    initDone() {

        this.isInitialized = true;
        this.isIntializing = false;
        this.props.routerHistory.push('/info');
        this.setState({
            isAcuantSdkLoaded:true
        })
    }

    render() {
        return (
            <div className={'mainContent'}>
                {this.state.isAcuantSdkLoaded ? <Provider store={this.props.store}>
                    <PersistGate loading={null} persistor={this.props.persistor}>
                        <ConnectedRouter history={this.props.routerHistory}>
                            <Switch>
                                 {/*<Redirect exact from="/" to="/capture/photo"/>*/}
                                <Route path="/user" render={(props)=> <Loading  {...props} loadScript={() =>this.loadScript()}/>}/>
                                <Route path="/info" exact component={Info}/>
                                <Route path="/capture/photo" exact component={CapturePhoto}/>
                                <Route path="/capture/camera" exact component={AcuantReactCamera}/>
                                <Route path="/photo/confirm" exact component={ProcessedImageResult} />
                                <Route path="/capture/selfie" exact component={CaptureSelfie}/>
                                <Route path='/results' component={Results}/>
                                <Route path='/success' component={Success}/>
                                <Route path="/error" component={Error}/>
                            </Switch>
                        </ConnectedRouter>
                    </PersistGate>
                </Provider>
                    :
                    <Provider store={this.props.store}>
                        <PersistGate loading={null} persistor={this.props.persistor}>
                            <ConnectedRouter history={this.props.routerHistory}>
                                <Switch>
                                    <Route path="/user" render={(props)=> <Loading  {...props} loadScript={() =>this.loadScript()}/>}/>
                                </Switch>
                            </ConnectedRouter>
                        </PersistGate>
                    </Provider>
                }
            </div>
        );
    }
}

export default App;
