import React, { Component, Fragment } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Header from "./Header";
import { sentData } from "./actions/processDataActions";

class Success extends Component {


    componentDidMount() {
        this.props.sentData()
    }

    render() {
        return (
            <Fragment>
                <Header />

                <div className='body column capture_photo'>
                    <div className='column wrapper big_title'>Done!</div>
                    <div className='row wrapper description_container'>
                        <p className='description' style={{textAlign:'center'}}>The authentication process is now complete. We will contact you if we have any questions about your authentication results.</p>
                    </div>
                </div>

            </Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ sentData }, dispatch);
}

export default connect(null,mapDispatchToProps)(Success);
