export function setUser(payload) {
    return {
            type: "@@acuant/SET_USER",
            data: payload
        }
}

export function removeUser() {
    return {
        type: "@@acuant/REMOVE_USER"
    }
}
