export function palidinReducer(state = {}, action) {
    switch (action.type) {
        case '@@acuant/SET_USER':
            return {
                ...state,
                user: action.data
            };
        case '@@acuant/REMOVE_USER':
            return {
                ...state,
                user: null
            }
        default:
            return state;
    }
}
